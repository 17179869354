import React from 'react';

const Title = () => {
  return (
    <div className="title">
      <div className="logo">
        {/* <h1><i className="fa fa-paw"></i> TazzyGram</h1> */}
        <h1><i className="fa fa-paw"></i>TazzyGram</h1>
      </div>
      <h2>This is Taz.</h2>
      <p>Some call him the Tazziest of all Bobs. Indeed, the chumbiest amongst every bubba.</p>

      <div className="box"></div>
    </div>
  )
}

export default Title;